import React, { useState, useEffect } from "react";
// import { Toast, ToastContainer, ToastHeader, ToastBody } from "react-bootstrap";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const ToastComponent = ({ showToast, message, header, color }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (showToast) {
      setShow(true);
      const timer = setTimeout(() => setShow(false), 3000); // Hide after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  return (
    <ToastContainer
      className="p-3"
      position="top-end"
      style={{ zIndex: 1 }}
    >
      <Toast
        show={show}
        onClose={() => setShow(false)}
        bg={color.toLowerCase()}
        delay={3000}
        autohide
      >
        <Toast.Header closeButton={false}>
          <strong className="me-auto">{header}</strong>
        </Toast.Header>
        <Toast.Body className={color === 'Dark' && 'text-white'}>{message}</Toast.Body>
      </Toast>
    </ToastContainer>

    // <ToastContainer
    //   className="p-3"
    //   position="bottom-end"
    //   style={{ zIndex: 11 }}
    // >
    //   <Toast
    //     show={show}
    //     onClose={() => setShow(false)}
    //     bg={color}
    //     delay={3000}
    //     autohide
    //   >
    //     <ToastHeader>
    //       <strong className="me-auto">{header}</strong>
    //     </ToastHeader>

    //     <ToastBody>{message}</ToastBody>
    //   </Toast>
    // </ToastContainer>
  );
};

export default ToastComponent;
